import { IAppMenu } from '../../../interfaces/IAppMenu';

export const getSectionName = (menus?: IAppMenu[], id?: boolean | string) => {
  let match;
  if (menus) {
    match = menus.find(menu => {
      return menu.id === id;
    });
  }
  const title = match && match.attributes && match.attributes.name;
  return title || '';
};
