import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M22,19.5c0.7,0.7,0.7,1.7,0,2.4c-0.7,0.7-1.7,0.7-2.4,0l0,0L12,14.2l-7.6,7.6c-0.7,0.7-1.7,0.7-2.4,0s-0.7-1.7,0-2.4L9.5,12 L2,4.5C1.3,3.9,1.3,2.8,2,2.2s1.7-0.7,2.4,0L12,9.8l7.6-7.6c0.7-0.7,1.7-0.7,2.4,0s0.7,1.7,0,2.4l0,0L14.5,12L22,19.5z" />
    </SvgIcon>
  );
};
