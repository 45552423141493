import { IContents } from '../../../interfaces/IContents';
import { IContentData } from '../../../interfaces/IContentData';

export const getTextFromContent = (
  content: IContents,
  attributeName: string,
  attributeValueToMatch: string
): string | undefined => {
  let textItem =
    content &&
    content.text &&
    content.text.find(textItem => {
      return (
        textItem[attributeName as keyof IContentData] === attributeValueToMatch
      );
    });
  return (textItem && textItem.content) || undefined;
};
