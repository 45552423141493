import { IAppMenu } from '../../../interfaces/IAppMenu';
import { IAppPage } from '../../../interfaces/IAppPage';
import { IFlattenedPage } from '../../../interfaces/IFlattenedPage';
import { getPageTitle } from '../getPageTitle/getPageTitle';
import { CTA, EMBEDDED } from '../../../constants/misc';

export const getFlattenedPages = (menus: IAppMenu[], pages: IAppPage[]) => {
  let flattenedPages: IFlattenedPage[] = [];
  menus.forEach(menu => {
    let children = menu.children;
    let menuType = menu.attributes && menu.attributes.type;
    if (menuType !== EMBEDDED && menuType !== CTA && children) {
      children.forEach(child => {
        flattenedPages.push({
          pageID: child.id || '',
          sectionID: menu.id || '',
          title: getPageTitle(pages, child.id),
        });
      });
    }
  });
  return flattenedPages;
};
