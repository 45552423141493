import { IAppPage } from '../../../interfaces/IAppPage';

export const getPageTitle = (pages?: IAppPage[], id?: boolean | string) => {
  let match;
  if (pages) {
    match = pages.find(page => {
      return page.id === id;
    });
  }
  const title = match && match.attributes && match.attributes.title;
  return title || '';
};
