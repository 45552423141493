import React from 'react';
import { getEventCoordinates } from '..';

export const getImageHoverPosition: any = (
  event: React.MouseEvent | React.TouchEvent,
  image: HTMLImageElement,
  container: HTMLElement
) => {
  const containerWidth = container.offsetWidth;
  const containerHeight = container.offsetHeight;
  const imageAspectRatio = image.naturalWidth / image.naturalHeight;
  const containerAspectRatio = containerWidth / containerHeight;
  let bgImageHeight;
  let bgImageWidth;
  let leftOffsetFromContainer;
  let topOffsetFromContainer;

  if (imageAspectRatio >= containerAspectRatio) {
    bgImageHeight = containerHeight;
    bgImageWidth = bgImageHeight * imageAspectRatio;
    leftOffsetFromContainer = (containerWidth - bgImageWidth) / 2;
    topOffsetFromContainer = 0;
  } else {
    bgImageWidth = containerWidth;
    bgImageHeight = bgImageWidth / imageAspectRatio;
    topOffsetFromContainer = (containerHeight - bgImageHeight) / 2;
    leftOffsetFromContainer = 0;
  }

  let { x, y } = getEventCoordinates(event);
  let { left, top } = container.getBoundingClientRect();
  x = x - left;
  y = y - top;
  const positionFromLeft = x - leftOffsetFromContainer;
  const positionFromTop = y - topOffsetFromContainer;

  return {
    percentageFromLeft: (positionFromLeft / bgImageWidth) * 100,
    percentageFromTop: (positionFromTop / bgImageHeight) * 100,
  };
};
