import { IAppData } from '../../../interfaces/IAppData';
import { getLookupByTerm } from '../getLookupByTerm/getLookupByTerm';

export const getTranslation = (
  key: string,
  fallback: string,
  data: IAppData
) => {
  let match = getLookupByTerm(data.releaseLookupsData, key);
  if (!match) {
    match = getLookupByTerm(data.lookupsData, key);
  }
  return match || fallback;
};
