export const getStyleVariantClasses = (
  baseClass: string,
  variants: string[],
  styles: any
) => {
  return variants
    .map(variant => {
      return styles[`${baseClass}_${variant}`];
    })
    .join(' ')
    .trim();
};
