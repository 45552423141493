import { ILookup } from '../../../interfaces/ILookup';

export const getLookupByTerm = (lookups: ILookup[], key: string): string => {
  let matchedValue: string = '';
  if (lookups && lookups.length && key) {
    for (let i = 0; i < lookups.length; i++) {
      const lookup = lookups[i];
      const match = lookup && lookup.terms && lookup.terms && lookup.terms[key];
      if (match) {
        matchedValue = match;
        break;
      }
    }
  }
  return matchedValue;
};
