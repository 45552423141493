import { CLOCKWISE, COUNTER_CLOCKWISE } from '../../../constants/misc';

export const getMovementDetails = (
  xFromCenter: number,
  yFromCenter: number,
  prevXFromCenter: number,
  prevYFromCenter: number,
  circumference: number
) => {
  const changeInX = xFromCenter - prevXFromCenter;
  const changeInY = yFromCenter - prevYFromCenter;
  const angle = Math.atan2(yFromCenter, xFromCenter);
  const prevAngle = Math.atan2(prevYFromCenter, prevXFromCenter);
  let direction;
  // When one crosses the y axis, this is the case
  if (Math.abs(prevAngle - angle) > Math.PI) {
    direction = prevAngle - angle < 0 ? COUNTER_CLOCKWISE : CLOCKWISE;
  } else {
    direction = angle < prevAngle ? COUNTER_CLOCKWISE : CLOCKWISE;
  }
  let distance = Math.sqrt(changeInX * changeInX + changeInY * changeInY);
  distance = direction === COUNTER_CLOCKWISE ? -distance : distance;
  const radians = (distance / circumference) * Math.PI * 2;
  return {
    direction,
    radians,
    angle,
  };
};
