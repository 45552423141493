import React from 'react';
import { DataContext } from '../../../containers/DataContainer/DataContext';
import { IAppData } from '../../../interfaces/IAppData';
import { getTranslation } from '../../../utilities/helpers/getTranslation/getTranslation';

interface Translation {
  fallback: string;
  lookupKey: string;
  render: Function;
}

export const Translation: React.FC<Translation> = ({
  lookupKey,
  fallback,
  render,
}): any => {
  return (
    <DataContext.Consumer>
      {(data: IAppData) => {
        const translation = getTranslation(lookupKey, fallback, data);
        return <>{render(translation)}</>;
      }}
    </DataContext.Consumer>
  );
};
