import { CLOCKWISE, COUNTER_CLOCKWISE } from '../../../constants/misc';
import { ISwatch } from '../../../interfaces/ISwatch';

export const getGalleryItemsOrder = (
  items: ISwatch[],
  amount: number,
  direction: string,
  angleBetween: number
) => {
  const endAngle = 0.75;
  const endItems: ISwatch[] = [];
  const inItems: ISwatch[] = [];
  let startPosition = 0;
  if (direction === CLOCKWISE) {
    items.forEach(item => {
      const currentAngle = item.angle || 0;
      const nextAngle = currentAngle + amount;
      const nextAngleInPI = nextAngle / Math.PI;
      if (nextAngleInPI > endAngle) {
        endItems.push(item);
      } else {
        inItems.push(item);
      }
    });
    items = [...inItems, ...endItems];
    startPosition = (items[0].angle || 0) + amount;
  } else if (direction === COUNTER_CLOCKWISE) {
    startPosition = (items[0].angle || 0) + amount;
    const lastItem = items[items.length - 1];
    if (lastItem.viewStatus === 'pre') {
      // As we are adding an item at the beginning, we want to start from the position of the next item along
      startPosition = startPosition + angleBetween;
      items.unshift(items.pop() as ISwatch);
    }
  }
  return { items, startPosition };
};
