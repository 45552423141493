import React from 'react';

export const getEventCoordinates = (
  event: React.TouchEvent | React.MouseEvent
) => {
  if (event.type === 'touchstart' || event.type === 'touchmove') {
    return {
      x: (event as React.TouchEvent).touches[0].clientX,
      y: (event as React.TouchEvent).touches[0].clientY,
    };
  } else {
    return {
      x: (event as React.MouseEvent).clientX,
      y: (event as React.MouseEvent).clientY,
    };
  }
};
