import { IFlattenedPage } from '../../../interfaces/IFlattenedPage';

export const getPrevNextPageDetails = (
  flattenedPages: IFlattenedPage[],
  pageID: string | undefined
) => {
  const indexInPages = flattenedPages.findIndex(
    flattenedPage => flattenedPage.pageID === pageID
  );
  let prevPage;
  let nextPage;
  if (indexInPages > -1) {
    prevPage = flattenedPages[indexInPages - 1];
    nextPage = flattenedPages[indexInPages + 1];
  }
  return { prevPage, nextPage };
};
