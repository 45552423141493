import React from 'react';
import styles from './Loading.module.scss';
import { Translation } from '../Translation';
export const Loading: React.FC<any> = props => {
  return (
    <div className={styles.loading}>
      <Translation
        fallback={'Loading...'}
        lookupKey={'loadingMessage'}
        render={(translation: string) => <div>{translation}</div>}
      />
    </div>
  );
};
