import React from 'react';
import { Link } from 'react-router-dom';
import { IFlattenedPage } from '../../../interfaces/IFlattenedPage';

import styles from './NextPage.module.scss';
import { getClassNames } from '../../../utilities/helpers';
interface NextPage {
  nextPage: IFlattenedPage;
  baseURL?: string;
  isLink?: boolean;
  variant?: string;
}
export const NextPage: React.FC<NextPage> = ({
  nextPage,
  baseURL,
  isLink = true,
  variant,
}) => {
  const classes = getClassNames([
    styles.nextPage,
    variant === 'light' ? styles.nextPage_alt : '',
  ]);
  return (
    <div className={classes}>
      {isLink && (
        <Link
          className={styles.nextPage__text}
          to={`${baseURL}/${nextPage.sectionID}/${nextPage.pageID}`}
          dangerouslySetInnerHTML={{ __html: nextPage.title }}
        />
      )}
      {!isLink && (
        <div
          className={styles.nextPage__text}
          dangerouslySetInnerHTML={{ __html: nextPage.title }}
        />
      )}
    </div>
  );
};
