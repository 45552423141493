import {
  DOTTED_INDICATOR,
  ICON_INDICATOR,
  IMAGE,
  IMAGE_ICON_INDICATOR,
} from '../../../constants/misc';
import { IContents } from '../../../interfaces/IContents';

export const getGalleryIndicatorType = (items: IContents[]) => {
  let isImageType: boolean = false;
  const allHaveIcons = items.every(item => {
    return !!(item.icon && Boolean(item.icon.name));
  });
  if (!allHaveIcons) {
    isImageType = items.every(item => {
      return Boolean(item.icon && item.icon.type === IMAGE);
    });
  }

  return allHaveIcons
    ? ICON_INDICATOR
    : isImageType
    ? IMAGE_ICON_INDICATOR
    : DOTTED_INDICATOR;
};
