import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { ScrollToTop } from './framework/atoms/ScrollToTop/ScrollToTop';
import { LandingPage } from './modules/LandingPage';
import './App.scss';
import { DataContainer } from './containers/DataContainer';
import { DataContext } from './containers/DataContainer/DataContext';
import theme from './themes/DefaultTheme';
import { LANDING_ROUTE, MOD_VAR_LOC_ROUTE, PAGE_ROUTE } from './constants';
import { ViewHeightSetter } from './framework/atoms/ViewHeightSetter';
import { CookiePolicyContainer } from './containers/CookiePolicyContainer/CookiePolicyContainer';
import { TrackingContainer } from './containers/TrackingContainer/TrackingContainer';
import { Loading } from './framework/atoms/Loading';

const MainLayoutContainer = lazy(() =>
  import('./containers/MainLayout.container.tsx')
);

const App: React.FC = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <ScrollToTop />
          <ViewHeightSetter />
          <Switch>
            <Route path={'/'} exact>
              <Redirect to={'/kuga/2020.5/en-gb/landing'} />
            </Route>
            <Route path={MOD_VAR_LOC_ROUTE}>
              <DataContainer>
                <DataContext.Consumer>
                  {data => (
                    <>
                      <CookiePolicyContainer data={data} />
                      <TrackingContainer data={data} />
                      <Switch>
                        <Route path={LANDING_ROUTE} exact>
                          <LandingPage data={data} />
                        </Route>
                        <Route path={PAGE_ROUTE}>
                          <Suspense fallback={<Loading />}>
                            <MainLayoutContainer data={data} />
                          </Suspense>
                        </Route>
                      </Switch>
                    </>
                  )}
                </DataContext.Consumer>
              </DataContainer>
            </Route>
          </Switch>
        </Router>
      </div>
    </MuiThemeProvider>
  );
};

export default App;
