import React from 'react';
import { ILookup } from '../../interfaces/ILookup';
import { getLookupByTerm } from '../../utilities/helpers/getLookupByTerm/getLookupByTerm';
import { CookiePolicy } from '../../framework/molecules/CookiePolicy';
import { CookiePolicyV2 } from '../../framework/molecules/CookiePolicyV2';

interface CookiePolicyContainer {
  data: {
    lookupsData: ILookup[];
  };
}

export const CookiePolicyContainer: React.FC<CookiePolicyContainer> = ({
  data,
}) => {
  const text =
    data &&
    data.lookupsData &&
    getLookupByTerm(data.lookupsData, 'cookiesPolicy');
    const defaultLookup = data.lookupsData[0];
  return (
    <>
      {defaultLookup && defaultLookup.cookiePolicy && defaultLookup.cookiePolicy.useV2Cookies && (
        <CookiePolicyV2 cookiePolicy={defaultLookup.cookiePolicy} />
      )}

      {defaultLookup && (!defaultLookup.cookiePolicy || (defaultLookup.cookiePolicy && !defaultLookup.cookiePolicy.useV2Cookies)) && text && (
        <CookiePolicy text={text} />
      )}
    </>
  );
};
