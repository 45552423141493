import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { LANDING_ROUTE, PAGE_ROUTE } from '../../constants/routes';
import { getParamFromRouteMatch } from '../../utilities/helpers/getParamFromRouteMatch/getParamFromRouteMatch';
import { IAppData } from '../../interfaces/IAppData';
import { getPageTitle } from '../../utilities/helpers/getPageTitle/getPageTitle';
import { getSectionName } from '../../utilities/helpers/getSectionName/getSectionName';
import { getLookupByTerm } from '../../utilities/helpers/getLookupByTerm/getLookupByTerm';
import { getStringWithoutMarkup } from '../../utilities/helpers/getStringWithoutMarkup/getStringWithoutMarkup';

interface TrackingContainer {
  data: IAppData;
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const TrackingContainer: React.FC<TrackingContainer> = ({ data }) => {
  let location = useLocation();
  let match = useRouteMatch(PAGE_ROUTE);
  let landingMatch = useRouteMatch(LANDING_ROUTE);
  let releaseTitle =
    getLookupByTerm(data.releaseLookupsData, 'releaseTitle') || '';
  React.useEffect(() => {
    let sectionID;
    let pageID;
    if (landingMatch && landingMatch.isExact) {
      const landingPage = data.pageData.find(page => {
        return page.component && page.component.componentType === 'landing';
      });
      pageID = landingPage && landingPage.id;
    } else {
      sectionID = getParamFromRouteMatch(match, 'sectionID');
      pageID = getParamFromRouteMatch(match, 'pageID');
    }
    const pageTitle = getPageTitle(data.pageData, pageID);
    const sectionTitle = getSectionName(data.menuData, sectionID);
    window.document.title = getStringWithoutMarkup(
      `${releaseTitle}${pageTitle}`
    );
    if (window && window.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
        pageTitle: pageTitle,
        sectionTitle: sectionTitle,
      });
    }
  }, [location, data, landingMatch, match, releaseTitle]);
  return null;
};
