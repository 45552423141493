import React, { Suspense, lazy } from 'react';

import styles from './LandingPage.module.scss';
import { IAppMenu } from '../../interfaces/IAppMenu';
import { Link } from 'react-router-dom';
import { IAppPage } from '../../interfaces/IAppPage';
import { NextPage } from '../../framework/atoms/NextPage';
import { getTextFromContent } from '../../utilities/helpers/getTextFromContent/getTextFromContent';
import { Loading } from '../../framework/atoms/Loading';
import { useHistory } from "react-router-dom";

const CoverVideo = lazy(() => import('../../framework/atoms/CoverVideo'));

type LandingPageProps = {
  initialState?: boolean;
  data: {
    menuData: IAppMenu[];
    pageData: IAppPage[];
    locale?: string;
  };
};
export const LandingPage: React.FC<LandingPageProps> = ({ data }) => {
  const firstSection = data && data.menuData && data.menuData[0];
  const firstSectionID = firstSection && firstSection.id;
  const firstPageUrl =
    (firstSection &&
      data.menuData[0].children &&
      data.menuData[0].children[0] &&
      data.menuData[0].children[0].id) ||
    '';
  const landingPage = data.pageData.find(page => {
    return page.component && page.component.componentType === 'landing';
  });

  let modalPage = '';

  data.menuData.forEach(menu => {
    if (menu && menu.children && landingPage && landingPage.id) {
      const localMenuItem = menu.children.find(
        page => page.id === landingPage.id
      );

      if (
        localMenuItem &&
        localMenuItem.children &&
        localMenuItem.children[0]
      ) {
        modalPage = `/?modalid=${localMenuItem.children[0].id}`;
      }
    }
  });

  const contents =
    landingPage &&
    landingPage.component &&
    landingPage.component.contents &&
    landingPage.component.contents;
  const content = contents && contents[0];
  const ovalImage = content && content.media && content.media.src;
  const heading = getTextFromContent(content, 'customStyle', 'heading');
  const buttonCopy = getTextFromContent(content, 'customStyle', 'subText');
  const backgroundMedia = content && content.largeMedia;
  const mediaSource = backgroundMedia && backgroundMedia.src;
  const backgroundMediaType = backgroundMedia && backgroundMedia.mediaType;
  const thumb = content && content.thumb;
  const thumbSrc = thumb && thumb.src;
  const [opacityCss, setOpacityCss] = React.useState(1);
  const style =
    backgroundMediaType === 'image'
      ? { backgroundImage: `url(${mediaSource})`, opacity: opacityCss }
      : { backgroundImage: `url(${thumbSrc})`, opacity: opacityCss };
  const firstPage = `${firstSectionID}/${firstPageUrl}${modalPage}`;
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const [scrollTotal, setScrollTotal] = React.useState(0);
  const history = useHistory();
  const swipeTrigger = -150;
  const scrollTrigger = 300;
  const handleWheelEvent = (e: React.WheelEvent<HTMLElement>) => {
    var scrollAmount = scrollTotal + e.deltaY;
    if (scrollAmount >= scrollTrigger) {
      history.push(firstPage);
    }
    else if (scrollAmount < 0) {
      setScrollTotal(0);
      scrollAmount = 0;
    }
    var opacity = scrollAmount / scrollTrigger;
    if (opacity < 1 && opacity > 0) {
      setOpacityCss(1 - opacity);
    }
    setScrollTotal(scrollAmount);
  };
  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    setTouchStart(e.targetTouches[0].clientY);
  }
  const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    setTouchEnd(e.targetTouches[0].clientY);
    var delta = e.targetTouches[0].clientY - touchStart;
    var opacity = delta / swipeTrigger;
    if (opacity < 1 && opacity > 0) {
      setOpacityCss(1 - opacity);
    }
  }
  const handleTouchEnd = (e: React.TouchEvent<HTMLElement>) => {
    if ((touchEnd - touchStart) < swipeTrigger) {
      history.push(firstPage);
    }
    else {
      setOpacityCss(1);
    }
  }
  return (
    <main lang={data.locale} 
      onWheel={handleWheelEvent}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className={styles.landing__container}>
      <Link
        to={firstPage}
        className={styles.landing__bg}
        style={style}
      >
        {content && (
          <>
            {backgroundMediaType === 'video' && (
              <div className={styles.landing__videoBg}>
                <Suspense fallback={<Loading />}>
                  <CoverVideo
                    videoSrc={mediaSource}
                    coverSrc={thumbSrc}
                    loop={backgroundMedia.loop !== false}
                  />
                </Suspense>
              </div>
            )}
            <div className={styles.landing__content}>
              {heading && (
                <h1 className={styles.landing__heading}>{heading}</h1>
              )}
            </div>
          </>
        )}
      </Link>
      {buttonCopy && (
        <div className={styles.landing__cta}>
          <Link
            to={firstPage}
            className={styles.landing__ctaLink}
          >
            <div className={styles.landing__logo}>
              {ovalImage && (
                <img src={ovalImage} alt="" className={styles.landing__oval} />
              )}
            </div>

            <NextPage
              nextPage={{ pageID: '', sectionID: '', title: buttonCopy }}
              variant={'light'}
              isLink={false}
            />
          </Link>
        </div>
      )}
    </main>
  );
};
