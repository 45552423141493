import React, { useState } from 'react';
import styles from './CookiePolicy.module.scss';
import { CloseIcon } from '../../atoms/Icons/CloseIcon';
import { getClassNames } from '../../../utilities/helpers';
import { IconButton } from '@material-ui/core';
import { Translation } from '../../atoms/Translation';
import { getGTM } from '../../../utilities/helpers/getGTM/getGTM';

interface CookiePolicy {
  text: string;
}

export const CookiePolicy: React.FC<CookiePolicy> = ({ text }) => {
  const [showCookie, setShowCookie] = useState(true);
  getGTM();
  return (
    <>
    <div
      className={getClassNames([
        styles.cookiesPolicy,
        showCookie ? '' : styles.cookiesPolicy_hidden
      ])}
    >
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </div>
    
    <div className={getClassNames([
        styles.cookiePolicy__close,
        showCookie ? '' : styles.cookiePolicy__close_hidden
      ])}>
      <Translation
        fallback={'Close cookies message'}
        lookupKey={'cookiesClose'}
        render={(translation: string) => (
          <IconButton
            aria-label={translation}
            onClick={() => setShowCookie(!showCookie)}
            color={'secondary'}
            className={getClassNames([styles.cookiesPolicy_button, 'cookie-banner-close'])}
          >
            <CloseIcon fontSize={'large'} />
          </IconButton>
        )}
      />
    </div>
    </>
  );
};
