import React from 'react';
import { getEventCoordinates } from '..';

export const getMagnifierPosition = (
  event: React.MouseEvent | React.TouchEvent,
  previewElement: HTMLDivElement,
  containerElement: HTMLDivElement
) => {
  const isMouse = event.type === 'mousemove';
  const touchOffset = 20;
  let { x, y } = getEventCoordinates(event);
  let {
    left: containerLeft,
    top: containerTop,
  } = containerElement.getBoundingClientRect();
  x = x - containerLeft;
  y = y - containerTop;
  let left: number = 0;
  let top: number = 0;

  if (previewElement) {
    const { offsetWidth, offsetHeight } = previewElement;
    const centerLeft = offsetWidth / 2;
    const centerTop = offsetHeight / 2;
    if (isMouse) {
      left = x - centerLeft;
      top = y - centerTop;
    } else {
      left = x - offsetWidth - touchOffset;
      top = y - offsetHeight - touchOffset;

      if (left <= 0) {
        left = x + touchOffset;
      }
      if (top <= 0) {
        top = y + touchOffset;
      }
    }
  }
  return { left: `${left}px`, top: `${top}px` };
};
