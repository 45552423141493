import React, { RefObject } from 'react';
import { getEventCoordinates } from '..';

export const getCoordinatesRelToCenter = (
  e: React.MouseEvent | React.TouchEvent,
  container: RefObject<HTMLDivElement>
) => {
  const event = { ...e };
  const boundingBox = (container &&
    container.current &&
    container.current.getBoundingClientRect()) || { left: 0, top: 0 };
  const { left, top } = boundingBox;
  const { x, y } = getEventCoordinates(event);
  return {
    x: x - left,
    y: y - top,
  };
};
