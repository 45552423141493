import React, { ReactNode, useEffect, useState } from 'react';
import { getFlattenedPages } from '../../utilities/helpers/';

import { DataContext } from './DataContext';
import { useParams } from 'react-router';
import { Loading } from '../../framework/atoms/Loading';
interface MenuContainerProps {
  children: ReactNode;
}
const baseUrl =
  process.env.NODE_ENV === 'production'
    ? '{{{API_URL}}}'
    : '/api';
const useBlob =
  process.env.NODE_ENV === 'production'
    ? '{{{USE_BLOB}}}'
    : 'true';
export const DataContainer = ({ children }: MenuContainerProps) => {
  const [data, setMenuData] = useState();
  const [errors, setMenuDataError] = useState(false);
  let { model, variation, locale } = useParams<{ model: string, variation: string, locale: string }>();
  function fetchData() {
    Promise.all([
      fetch(getDataUrl(baseUrl, model, variation, locale, 'Menus')).then(value =>
        value.json()
      ),
      fetch(getDataUrl(baseUrl, model, variation, locale, 'Pages')).then(value =>
        value.json()
      ),
      fetch(getDataUrl(baseUrl, model, variation, locale, 'Specs')).then(value =>
        value.json()
      ),
      fetch(getDataUrl(baseUrl, model, variation, locale, 'Lookups')).then(value => 
        value.json()
      ),
      fetch(getDataUrl(baseUrl, model, variation, locale, 'ReleaseLookups')).then(
        value => value.json()
      ),
    ])
      .then(allResponses => {
        const menuData = allResponses[0];
        const pageData = allResponses[1];
        const specData = allResponses[2];
        const lookupsData = allResponses[3];
        const releaseLookupsData = allResponses[4];
        const flattenedPages = getFlattenedPages(menuData, pageData);
        setMenuData({
          menuData,
          pageData,
          specData,
          lookupsData,
          releaseLookupsData,
          flattenedPages: flattenedPages,
          locale,
        });
      })
      .catch(() => setMenuDataError(true));
  }
  function getDataUrl(baseUrl: string, model: string, variation: string, locale: string, dataFile: string) {
    if (useBlob === 'true') {
      return `${baseUrl}/${model}/${variation}/${locale}/json/${dataFile}.json`;
    }
    else {
      return `${baseUrl}/${dataFile}/${model}/${variation}/${locale}`;
    }
  }
  useEffect(() => {
    if (!data) {
      fetchData();
    }
  });
  return (
    <>
      {!data && !errors && <Loading />}
      {errors && <h1>Something went wrong</h1>}
      {data && (
        <>
          <DataContext.Provider value={data}>
            <>{children}</>
          </DataContext.Provider>
        </>
      )}
    </>
  );
};
