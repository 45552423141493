import React, { useEffect } from 'react';
export const ViewHeightSetter: React.FC<any> = props => {
  const setHeight = () => {
    const root = document.documentElement;
    const windowHeight = window.innerHeight;
    root.style.setProperty('--full-height', `${windowHeight}px`);
  };

  useEffect(() => {
    setHeight();
    window.addEventListener('resize', setHeight);

    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  return null;
};
