import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#00095B',
      dark: '#10192f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#f0f0f0',
      contrastText: '#10192f',
    },
  },
  overrides: {
    MuiFab: {
        root: { 
            height: 48,
            width: 48,
            boxShadow: 'none',
        },
    },
  }
});

export default theme;
