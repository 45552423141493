export const STRETCH = 'stretch';
export const NO_STRETCH = 'no-stretch';
export const COVER = 'cover';
export const CONTENT = 'content';
export const BODY_AND_COMPONENT = 'body-and-component';
export const BODY_ONLY = 'body-only';
export const COMPONENT_ONLY = 'gallery-only';
export const BUTTON_WATCH_VIDEO = 'buttonWatchVideo';
export const CLOCKWISE = 'clockwise';
export const COUNTER_CLOCKWISE = 'counterClockwise';
export const GALLERY = 'gallery';
export const SWATCH_GALLERY = 'swatchGallery';
export const PAGE_GALLERY = 'pageGallery';
export const IMAGE_REVEAL = 'imageReveal';
export const HOTSPOT = 'hotspot';
export const MIN_SWATCH_ITEMS = 12;
export const EMBEDDED = 'embedded';
export const CTA = 'cta';
export const DOTTED_INDICATOR = 'dotted-indicator';
export const ICON_INDICATOR = 'icon-indicator';
export const IMAGE_ICON_INDICATOR = 'image-icon-indicator';
export const ICON = 'icon';
export const IMAGE = 'image';
export const LEFT = 'left';
export const CENTER = 'center';
export const RIGHT = 'right';
export const NUMBER = 'number';
export const HIDDEN = 'hidden';
export const SMALL = 'small';
export const MEDIUM = 'medium';
export const FULL = 'full';
export const LINK = 'link';
export const GALLERY_ANIMATION_TYPE_CAROUSEL = 'gallery-animation-carousel';
export const GALLERY_ANIMATION_TYPE_FADE = 'gallery-animation-fade';
